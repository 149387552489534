.menu-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    z-index: 2;
    cursor: pointer;


    i{
        // background: #fff ;
        width: 40px;
        height: 2px;
        margin-bottom: 6px;
        border-radius: 2px;
        // transform-origin: center;
        transition: all ease .5s;
    }

    .open:nth-child(1){
        transform: rotate(45deg) translateY(12px);
        width: 40px;
    }
    .open:nth-child(2){
        // opacity: 0;
        width: 0px;
        // transform: translateX(-16px);
    }
    .open:nth-child(3){
        transform: rotate(-45deg) translateY(-12px);
        width: 40px;

    }
    .close:nth-child(1){
        transform: rotate(0) translateY(0);
    }
    .close:nth-child(2){
        opacity: 1;
        // width: auto;
    }
    .close:nth-child(3){
        transform: rotate(0) translateY(0);
    }
}