.panel-modal{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(26, 39, 42, 0.6);
    display: none;
    z-index: 200000;
    opacity: 0;

}

.panel-modal-con{
    width: 80%;
    height: 100%;
    margin: auto;
    padding: 40px;
    background: linear-gradient(100deg, #204860 0%, #00040b 100%);
   
}


.modal-header{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 100px;
    font-family: "griffon", "Aviano", "Times New Roman";
    /* color: ; */
}

.modal-return{
    cursor: pointer;

}

.modal-return:hover{
    opacity: 0.6;
}












@media (min-width: 0px) and (max-width: 768px){

    .panel-modal-con{
        width: 100%;
    }
  
}

@media (min-width: 0px) and (max-width: 660px){

    .modal-header{
       margin-bottom: 50px;
    }
  
}
@media (min-width: 0px) and (max-width: 440px){

    .panel-modal-con{
       padding: 40px 20px;
    }
  
}

