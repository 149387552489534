.home-form {
	margin: 10px auto;
	width: 100%;
	height: 100%;
	opacity: 1;

}

.home-form .inp {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
	margin-bottom: 1rem;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 600;
}

.input-control {
	width: 100%;
	height: fit-content;
	margin-bottom: 10px;

}

.input-control input {
	width: 100%;
	height: 50px;
	outline: none;
	border: 1px solid #B1BFD8;
	background: transparent;
	padding: 5px 15px;
	font-size: 16px;
	color: #B1BFD8;

}

.input-control select {

	width: 100%;
	height: 60px;
	outline: none;
	border: 1px solid #B1BFD8;
	background: transparent;
	padding: 5px 15px;
	font-size: 16px;
	color: #B1BFD8;

	margin-top: 5px;

}


.input-control textarea {
	margin-top: 5px;
	height: 200px;
	font-size: 16px;
	width: 100%;
	background: transparent;
	padding: 20px;
	outline: none;
	overflow: hidden;
	color: #B1BFD8;
	border: 1px solid #B1BFD8;

}

.btn {
	display: flex;
	justify-content: space-around;
	margin-top: 20px;
}


.attach,
.submit {

	width: fit-content;
	height: fit-content;
	outline: none;
	border: 1px solid var(--white);
	color: var(--white);
	cursor: pointer;
	position: relative;
	transition: 0.4s ease-in-out;
	z-index: 1;
	font-weight: 600;
	background: transparent;
	position: relative;
	z-index: 1;
	padding: 10px 40px;

}

.attach::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: white;
	z-index: -1;
	/* opacity: 0.2; */
	transform: scaleX(0);
	transform-origin: right;
	transition: transform 0.4s ease-in-out;
}

.attach:hover::after {
	transform: scaleX(1);
	transform-origin: left;
}

.attach:hover {
	background: transparent;
	transition: all 0.4s ease-in-out;
	/* border: 1px solid #243553; */
	color: #243553;
}

.submit {
	background: var(--white);
	color: #243553;
	font-weight: 600;
}

.submit:hover {
	background: transparent;
	transition: all 0.4s ease-in-out;
	border: 1px solid #243553;
	color: var(--white);
}

.submit::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #243553;
	z-index: -1;
	/* opacity: 0.2; */
	transform: scaleX(0);
	transform-origin: right;
	transition: transform 0.4s ease-in-out;
}

.submit:hover::after {
	transform: scaleX(1);
	transform-origin: left;
}

option {
	background: #fff;
	color: #243553;
	outline: none;
	border: none;
}

input:focus,
textarea:focus,
select:focus {
	border: 1px solid var(--gold);
	transition: all 0.5s ease-in-out;
}

@media (min-width: 0px) and (max-width: 660px) {

	.home-form .inp {
		display: flex;
		flex-wrap: wrap;
	}

}

@media (min-width: 375px) and (max-width: 440px) {

	label {
		font-size: 3.5vw;
	}
/* 
	.attach,
	.submit {

		font-size: 3.5vw;

	} */

}

@media (min-width: 0px) and (max-width: 375px) {

	label {
		font-size: 4vw;
	}


	.attach,
	.submit {

		font-size: 3.5vw;

	}

}