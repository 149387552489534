

.footer-container{
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    opacity: 1;
    z-index: 2001;
    opacity: 1;
}




.f-1{
    width: 40%;
    height: 100%;
    border-right: 0.5px solid white;
}

.f-2{
    width: 60%;
    height: 100%;
  
}

.section-left{
	background: transparent;
	width: 100%;
	height: 100%;
	padding: 40px 0;
    display: block;
	overflow-y: hidden;
	
}

.f-1-1, .f-1-3{
    width: 100%;
    height: 20%;
    padding: 0 30px 40px 50px;
}

.f-1-2{
    width: 100%;
    height: 60%;
    border-top: 0.5px solid white;
    border-bottom: 0.5px solid white;
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.f-1-3{
    padding: 20px 50px ;
    display: flex;
    justify-content: center;
    align-items: center;
}



.f-logo {
	display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
	text-align: center;
	text-decoration: none;
	
}
.f-logo-pic {
	font-size: 2rem;
	color: var(--green);
    width: fit-content;
    
}
.f-logo-pic svg {
	font-size: 2rem;
	color: var(--green);
	width: 100%;
	height: 100%;
	fill: var(--green);
}
.f-logo-text {
	font-size: 24px;
	color: var(--white);
	font-family: "Botanika", "Orelo SemiWide Heavy";

}


.f-heading-1{
	font-family: "Botanika", "Orelo SemiWide Heavy";
    font-size: 62px;
    line-height: 82px;
    font-weight: bold;
    text-align: left;
}

.social{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
	justify-content: flex-start;
    
}


.social-h2 {
	font-size: 18px;
	opacity: 1;
    text-align: center;
    margin-bottom: 10px;
  
}

.social-links {
    width: 100%;
    height: 100%; 
	display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;

}


.social-links-li a{
    font-size: 18px;
    color: #fff;
    font-weight: 500;
	
}

.social-links-li a:hover {
	opacity: 0.8;
    text-decoration: line-through;	
	transition: all 0.3s ease-in-out;
}


.footer-body{

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 60px ;
  
}

.footer-body-1{
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 20%;
   
}


.footer-body-2{
    width: 100%;
    height: 5%;
    text-align: center;
    
}


.footer-body-1 a{
    text-decoration: none;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #010712;
    text-align: center;
    transition: all ease-in-out 0.5s;

}

.footer-body-1 a:hover{
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.copyright-icon{
    font-size: 14px;
}




/* mobile */


.m-footer-sidebar, .m-footer-body{
    display: none;

}


.m-f-1-1{
    width: 100%;
    height: 20%;
    padding: 20px 30px;
    border-bottom: 0.5px solid white;

}


.m-f-1-1 a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    height: 100%;
    text-decoration: none;
    margin: auto;
    
}

.m-f-logo-pic svg {
    width: 100%;
    fill: var(--green);


}
.m-f-logo-text {
    font-size: 24px;
    font-weight: bold;
    color: var(--white);
    letter-spacing: 1px;
    font-family: "Botanika", "Orelo SemiWide Heavy";
    z-index: 2;
}


.m-f-1-2{
    width: 100%;
    height: 70%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.m-f-heading-1{
    font-family: "Botanika", "Orelo SemiWide Heavy";
    font-size: 72px;
    line-height: 82px;
    font-weight: bold;
    text-align: center;
}



.m-footer-body{

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px 40px 5px 40px; */

}


.m-f-btns{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid white;    
    padding: 10px 40px;

}

.m-f-btns a{
    text-decoration: none;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #010712;
    text-align: center;
    transition: all ease-in-out 0.5s;

}

.m-f-btns a:hover{
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.m-socials{
    width: 100%;
    height: 30%;
    padding: 20px;
   
}

.m-copy{
    width: 100%;
    height: 5%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
   
}



@media (min-width: 769px) and (max-width: 880px){


    .footer-body-1{
        flex-direction: column;
    }

    .f-1-2{
       padding: 10px 20px;       
       
    }

    .f-heading-1{
        font-size: 7vw;
    }

    .social-links{
        flex-direction: column;
    }

    .social-links a{
        font-size: 16px;
    }
  
}


@media (min-width: 0px) and (max-width: 768px){

    .m-footer-sidebar, .m-footer-body{
        display: block;
    }

   

    .footer-container{
        flex-direction: column;
    }
    
    .f-1{
        width: 100%;
        height: 50%;
        border-right: none;
        border-bottom: 0.5px solid white;
    }
    .f-2{
        width: 100%;
        height: 50%;
    
    }
    
    .section-left{
        display: none;
    }
    
    .footer-body{
        display: none;
    }


}

@media (min-width: 0px) and (max-width: 768px){

    .m-footer-sidebar, .m-footer-body{
        display: block;
    }

   

    .footer-container{
        flex-direction: column;
    }
    
    .f-1{
        width: 100%;
        height: 50%;
        border-right: none;
        border-bottom: 0.5px solid white;
    }
    .f-2{
        width: 100%;
        height: 50%;
    
    }
    
    .section-left{
        display: none;
    }
    
    .footer-body{
        display: none;
    }


}


@media (min-width: 570px) and (max-width: 700px){

    .m-f-1-1 a{
        width: 50%;
    }

    .m-f-heading-1{
        font-size: 8.5vw;
       
    }


}



@media (min-width: 485px) and (max-width: 570px){

    .m-f-1-1 a{
        width: 60%;
    }

    .m-f-heading-1{
        font-size: 9vw;
       
    }


}



@media (min-width: 390px) and (max-width: 485px){

    .m-f-1-1 a{
        width: 80%;
    }

    .m-f-heading-1{
        font-size: 11vw;
       
    }


}


@media (min-width: 0px) and (max-width: 390px){

    .m-f-1-1 a{
        width: 100%;
    }

    .m-f-heading-1{
        font-size: 10vw;
       
    }

    .m-f-btns a{
        width: 80px;
        height: 80px;
        font-size: 4vw;
    }


}