:root {
	--green: #b2ff55;
	--white: #ffffff;
	--margin: 10px;
}

.header {

	position: fixed;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 3% 0 8% 0;
	top: 0;
	left: 0;
    width: 28%;
    z-index: 2;
    height: 100vh;
	border-right: solid 1px gray;

	
}




.logo {
	display: block;
	text-align: center;
	text-decoration: none;
	margin-bottom: 40px;
	
}
.logo-pic {
	font-size: 3rem;
	color: var(--green);
}
.logo-pic svg {
	font-size: 3rem;
	width: 25%;
	height: 25%;
	z-index: 2;
}
.logo-text {
	font-size: 24px;
	font-family: "Botanika", "Orelo SemiWide Heavy";
	z-index: 2;
}


/* .active::after {
	content: "";
	width: calc(2rem + 8px);
	height: 1px;
	background-color: var(--green);
	margin-left: 7%;
}
.active::before {
	content: "";
	width: calc(2rem + 8px);
	height: 1px;
	background-color: var(--green);
	margin-right: 7%;
	
} */

.navbar{
	width: 100%;
	height: fit-content;
}


.navbar ul {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px 10px 10px 40px;

}



.navbar ul li {
	
	width: 90%;
	list-style: none;
	margin-bottom: 10px;

}



.item-el{
	display: inline-block;
	width: 40px;
	height: 2px;
	margin-left: 20px;
}


.navbar ul li a {
	text-decoration: none;
	font-size: 24px;
	font-family: "griffon", "Aviano", "Times New Roman";
	text-align: center;
	font-weight: 500;
	display: inline-block;
	width: fit-content;
	height: fit-content;

}


.navbar ul li a:hover {
	text-decoration: line-through;
	transition: 0.5s;

}

.mobile-navbar{
	display: none;

}


.menu-bar {
	display: none;
	border: 1px solid white;
	width: 5%;
	height: fit-content;
	color: #fff;
}
.mobile-nav {
	display: none;
}




@media (min-width: 0px) and (max-width: 1024px){

	.header{
		display: none;
	}

}

/* @media (max-width: 850px) {
	.sidebar{
		display: none;
	}

	.mobile-navbar{
		
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 8vh;
		padding: 0 5%;
		border-right: none;
		border-bottom: solid 1px gray;
		position: fixed;
		background-color: #233452;
		z-index: 1000;
		top: 0px;
		left: 0px;		
		opacity: 1;
	}

	.menu-mobile-navbar{
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 8vh;
		padding: 0 5%;
		border-right: none;
		border-bottom: solid 1px gray;
		position: fixed;
		background-color: #233452;
		z-index: 1000;
		top: 0;
		left: -100%;		
		opacity: 1;

	}



	.mobile-navbar a, .menu-mobile-navbar a{
		height: 40px;
		width: 25%;
		text-decoration: none;
		border: 1px solid white;
		
	}
	.m-logo-pic {
	
		width: 20px;
		height: 20px;
		position: relative;
	}
	.m-logo-pic svg {
		
		width: 20px;
		height: 20px;
		fill: var(--green);
		position: absolute;
		left: 0;
		top: 0;
	}
	.m-logo-text {
		font-size: 0.875em;
		color: var(--white);
		font-family: "Orelo SemiWide Heavy";
		text-align: left;
	}
	.navbar {
		display: none;
	}
	.mobile-nav {
		background: darkslategray;
		width: 100%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 7.5%;
		transition: all 5s ease;
	}
	
	.menu-bar {
		display: block;
		
	}

	.menu-icon{
		font-size: 2em;
		cursor: pointer;
		font-weight: 500;
	
	}
} */
