:root {
	--green: #b2ff55;
	--white: #ffffff;
	--margin: 10px;
	--font: Orelo SemiWide Heavy;
	--font-mediun: Botanika Medium;
	--big-font: "griffon", "Aviano", "Times New Roman";

	--gold: #f7dd95;

}



.contacts-scroller {

	height: 100%;
	width: 100%;

}

.c-one {

	height: 100vh;

}

.c-two {

	height: fit-content;
	padding: 40px 8% 80px 8%;


}

.c-three {
	height: fit-content;
	padding: 40px 10% 80px 10%;

}

.c-four {
	height: 50vh;
	padding: 40px 10% 80px 10%;

}

.c-five {
	height: 100vh;
}

.footer {
	width: 100%;
	height: 100%;
	background: #18173b;
	color: #fff;
	display: block;
	opacity: 1;
	z-index: 2001;
	position: relative;
	top: 0;
	left: 0;

}


.contact-panel {
	width: 100%;
	
}

.contact-bg {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: center;

}

.intro-section {

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 40px 2% 80px 2%;

}

.contact-panel .intro-h1 {
	font-size: 86px;
	font-family: var(--big-font);
	width: 80%;
	line-height: 1;
	margin-bottom: 20px;

}

.contact-panel .intro-p {
	width: 65%;
	font-size: 18px;
	line-height: 32px;
	letter-spacing: 1px;
	opacity: 0.8;

}




.contact-con {
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}

.sub-intro-h1 {
	font-size: 42px;
	font-family: var(--font-mediun);
	width: 100%;
	line-height: 1;
	margin-bottom: 40px;
	text-align: center;
}


.contact-inner {
	height: 100%;
	width: 72%;


}

.contact-card-form {
	height: 100%;

	visibility: hidden;

}

.contact-card-list {
	height: fit-content;
}

.contact-card-socials {
	width: 60%;
	height: fit-content;
	visibility: hidden;

}

.contact-icons {
	width: 70%;
	height: 60px;
	margin: auto;

}

.contact-social {

	display: flex;
	justify-content: space-between;
}

.contact-img {
	width: 50px;
	height: 50px;

}

.contact-img svg {

	width: 100%;
	height: 100%;
}




.contact-card {
	width: 100%;
	border-radius: 5px;
	margin-bottom: 40px;
	opacity: 0;
	border: 0.5px solid grey;
}

.contact-card-header {
	width: 100%;
	height: fit-content;
	padding: 10px 20px;
	text-align: left;
	font-size: 18px;
	font-weight: 500;
}

.contact-card-con {
	width: 100%;
	height: 100%;
	border-top: 0.5px solid grey;

}

.contact-card-con-1 {
	width: 100%;
	padding: 40px 0 60px 0;
}

.contact-card-con-inner {
	width: 100%;
	height: 90%;
	padding: 20px;
	display: flex;

	align-items: center;
	justify-content: space-between;

}

.contact-card-icon {
	width: 23%;
	height: 100%;
	display: "flex";
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	
}

.contact-card-icon svg {
	
	color: var(--white);
	width: 25%;
	height: 25%;
	fill: var(--white);
	opacity: 0.5;

}




.contact-card-text-con {
	width: 75%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	
}

.contact-card-text {
	font-size: 40px;
	font-family: var(--font-mediun);
}



@media (min-width: 1024px) and (max-width: 1180px){


	.contact-panel .intro-h1 {
		font-size: 6.25vw;
		
	}

	.contact-card-icon {
		width: 18%;
	
	
	}
	
	.contact-card-icon svg {
		
		width: 30%;
		height: 30%;
		
	}

	
	.contact-card-text-con {
		width: 80%;		
	
	}

	.contact-card-text{
		font-size: 3.5vw;
	}

}


@media (min-width: 0px) and (max-width: 1024px){

	.contacts-scroller {

		height: calc(100vh - 120px);
		 
	}
	
	
	  .contact-panel {
		top: 100px;
	
	   
	  }

	  .contact-bg{
		justify-content: center;

	  }

	  .contact-inner {		
		width: 100%;
	
	}

	.contact-card-icon {
		width: 15%;
	}

	.contact-card-icon svg {
		
		width: 50%;
		height: 50%;
		
	}

	
	.contact-card-text-con {
		width: 85%;		
	
	}

	
  
}

@media (min-width: 960px) and (max-width: 1024px){

	
	.contact-panel .intro-h1 {
		font-size: 6.5vw;
		
	}


}

@media (min-width: 860px) and (max-width: 960px){

	
	.contact-panel .intro-h1 {
		font-size: 6.75vw;
		
	}


}

@media (min-width: 768px) and (max-width: 860px){

	
	.contact-panel .intro-h1 {
		font-size: 7vw;
		
	}


}

@media (min-width: 0px) and (max-width: 768px){

	
	.c-three {
		height: fit-content;
		padding: 40px;

	}

	.contact-panel .intro-h1{
		width: 100%;
	}

	.contact-panel .intro-p{
		width: 90%;
	}
	/* .contact-card-socials {
		width: 100%;
	
	
	} */

}


@media (min-width: 660px) and (max-width: 768px){

	.contact-panel .intro-h1 {
		font-size: 8vw;
		
	}
	
	.collab-text{
		font-size: 6vw;
	}

	.contact-card-text {
		font-size: 5vw;
		
	}
}

@media (min-width: 560px) and (max-width: 660px){

	.contact-panel .intro-h1{
		font-size: 9vw;
	}

	.contact-panel .intro-p{
		font-size: 2.5vw;

	}

	.collab-text{
		font-size: 6.5vw;
	}
	.contact-card-text {
		font-size: 5.5vw;
		
	}
}

@media (min-width: 0px) and (max-width: 560px){

	.c-two {

		padding: 40px 20px 80px 20px;	
	
	}

	.c-three, .c-four {
		padding: 40px 10px 80px 10px;	
	
	}

	.contact-img {
		width: 40px;
		height: 40px;
	
	}

}


@media (min-width: 460px) and (max-width: 560px){

	.contact-panel .intro-h1{
		font-size: 10vw;
	}

	.contact-panel .intro-p{
		font-size: 3vw;

	}

	.sub-intro-h1{
		font-size: 6.75vw;

	}

	.collab-text{
		font-size: 7vw;
	}

	.contact-card-text {
		font-size: 6vw;
		
	}
}


@media (min-width: 360px) and (max-width: 460px){

	.contact-panel .intro-h1{
		font-size: 10vw;
	}

	.contact-panel .intro-p{
		font-size: 3.5vw;

	}

	.sub-intro-h1{
		font-size: 6.5vw;

	}

	.collab-text{
		font-size: 8vw;
	}



	.contact-card-header {	
		padding: 10px;
	
	}

	.contact-card-con-inner {
		padding: 20px 10px;
		
	}

	.contact-card-text {
		font-size: 6vw;
		
	}
}

@media (min-width: 0px) and (max-width: 360px){

	.contact-panel .intro-h1{
		font-size: 10vw;
	}

	.contact-panel .intro-p{
		font-size: 4vw;

	}

	.sub-intro-h1{
		font-size: 8vw;

	}


	.collab-text{
		font-size: 8vw;
	}
	.contact-card-header {	
		padding: 10px;
	
	}

	.contact-card-con-inner {
		padding: 20px 10px;
		
	}

	.contact-card-text {
		font-size: 6vw;
		
	}
}