.skeleton{
    background: #92949a;
    margin: 10px 0;
    opacity: 0.6;
}


.skeleton.title{
    width: 100%;
    height: 150px;

}
.skeleton.sub-title{
    width: 80%;
    height: 30px;
}

.skeleton.text{
    width: 100%;
    height: 150px;
}