.c-canvas{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .2s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: -1;
    /* background: #081832; */

}



.back-g {

    background-position: center;
    background-repeat: no-repeat;	
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: -1;

    /* background: linear-gradient(151deg, #081832 0%, #00040b 100%); */
    
}

.sphere-bg{
    width: 100%;
    height: 100%;	
	opacity: 0.3; 
    z-index: -1;


}


/* .scroller{
    width: 100%;
    height: 100%;
    border: 1px solid white;

}*/


.app-page-container{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

@media (min-width: 0px) and (max-width: 1024px){

    .app-page-container{
        height: calc(100vh - 100px);
        top: 100px;
    }
}






